<template>
    <zw-sidebar @shown="shown"
                :title="form.id ? $t('common.form.title.editCashInvoice')  + ' (' + form.id + ')' : $t('common.form.title.createCashInvoice')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="4">
                        <zw-new-date-group v-model="form.belegdatum"
                                           name="belegdatum"
                                           :label-prefix="labelPrefix"
                                           :readonly="readOnly"
                                           :format="format"
                                           validate="required"
                                           :validate="{required:true}"
                        ></zw-new-date-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="form.belegcycle"
                                         :options="[{value:null,title:'Kürzel'}]"
                                         name="belegcycle"
                                         text-field="title"
                                         value-field="value"
                                         :label-prefix="labelPrefix"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.belegnr"
                                        name="belegnr"
                                        type="number"
                                        :label-prefix="labelPrefix"
                                        :validate="{required:true}"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-select-group v-model="template"
                                         name="template"
                                         :label-prefix="labelPrefix"
                                         text-field="title"
                                         value-field="id"
                                         :options="getKbText()"
                                         @change="templateSelected"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.buchung"
                                        name="buchung"
                                        type="text"
                                        :label-prefix="labelPrefix"
                                        :validate="{required:true}"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <zw-select-group v-model="form.br_netto"
                                         :options="['brutto','netto']"
                                         name="br_netto"
                                         :label-prefix="labelPrefix"
                                         :validate="{required:true}"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="4">
                        <zw-input-group v-model="form.betrag"
                                        name="betrag"
                                        type="number"
                                        :label-prefix="labelPrefix"
                                        :validate="{required:true}"
                        ></zw-input-group>
                    </b-col>

                    <b-col sm="4">
                        <zw-select-group v-model="form.tax"
                                         :options="getVats() | optionsVL"
                                         name="tax"
                                         :label-prefix="labelPrefix"
                                         :validate="{required:true}"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-select-group v-model="form.konto"
                                         name="konto"
                                         :label-prefix="labelPrefix"
                                         :options="getRechnungskonto()"
                                         valueField="title"
                                         textField="title"
                                         :validate="{required:true}"
                        ></zw-select-group>
                    </b-col>
                </b-row>
                <b-row align-h="end">
                    <b-col sm="3" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>

            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwNewDateGroup from "../../components/ZwNewDateGroup";

export default {
    components: {ZwNewDateGroup},
    mixins: [commonSave],

    data() {
        return {
            loading: true,
            labelPrefix: 'cash_invoice.label.',
            payload: {},
            callback: null,
            readOnly: false,
            defaultForm: {
                tax: '19',
                buchung: '',
                konto: null,
            },
            template: null,
            form: {},
            format: 'DD.MM.YYYY',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getVats', 'getRechnungskonto', 'getKbText']),
        ...mapGetters('CashInvoices', ['getCashInvoice']),
        templateSelected() {
            if (this.template) {
                const template = this.getKbText().find(template => template.id == this.template)
                if (template) {
                    this.form.buchung = template.title
                    this.form.konto = template.konto_id
                    this.form.tax = template.tax
                }
            }
        },
        shown() {
            this.$store.dispatch('CashInvoices/fetchCashInvoice', {
                id: this.payload.id,
            })
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getCashInvoice()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('CashInvoices/saveCashInvoice', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>